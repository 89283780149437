.app-wrapper {
  position: absolute;
  overflow: auto;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;

  &--panelIsOpen {
    width: calc(100% - 340px);
  }
}

.pi_ba {
  z-index: 1000001 !important;
}

.pi_5 {
  z-index: 2000002 !important;
}

.pi_4,
.pi_6 {
  z-index: 2000002 !important;
}
