
.clients_page{
   position:absolute;
   width: 100%;
   overflow: auto !important;
   
  
}

.treeGroup{ 
   margin-top: 0px;
   padding-top: 0px !important;
}


.header{
   padding-top: 0px !important;
}
.ms-GroupSpacer{
   width: 0px !important;
}

.item__field{
   display: flex;
   flex: 1;
   height: 40px;
   flex-shrink: 0;
   // margin: "4px";
    justify-content: center;
    align-items: center;
   text-align: center;
   //margin-left: 10px;
   //flex-shrink: 0;
   //min-width: max-content;
   overflow: hidden;
   
}

.item{
   height: 40px;
 //  overflow: hidden;
   display: flex;
   flex-shrink: 0;
   background-color: #F1F7FB;
   align-items: center;
//   overflow: scroll ;
    
}




.item_list{
   position: relative;
 // height: 90vh;
  width: 1910px;
  flex-shrink: 0;
   //overflow-y: auto;
 //white-space: nowrap;
}

.item:hover {
 background-color: #CADFF0 !important;
}

.sticky{
   padding-top: 0px !important;
}

.item_dark{
   display: flex;
   background-color: #F1F7FB;
   align-items: center;
}

.item_dark:hover {
   background-color: #091B29 !important;
  }

.item__misingMandate{
   color:red;
   font-style: italic;
}





